import React from 'react';
import { useSelector } from 'react-redux';
import {Route,Redirect} from 'react-router-dom';

export const Private = ({children,...rest}) => {

  const {logueado} = useSelector(state => state.usrReducer);



  return (
    
      <Route {...rest} >
        { 
          (logueado  )
              ?  children
              : <Redirect to='/login' /> 
        }
        
      </Route>
   
   
  )
}
