import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { ModalBase } from '../../components/Ui/ModalBase';
import { MenuNavegacion } from '../../components/MenuNavegacion/MenuNavegacion';
import { Spiner } from '../../components/Ui/Spiner';
import { 
  startMostarBases, 
  startMostarInstitucionesCargadas, 
  startMostrarProyUltTresAnios, 
  startMostrarUltimoAviso 
} from '../../actions/bases';

import { InicioRouter } from '../../router/Inicio/InicioRouter';
import { startGetConvocatoria } from '../../actions/convocatoria';
import { resetUi } from '../../actions/ui';

export const Inicio = () => {

   const dispatch = useDispatch();

 

    const {
        cargandoBases,
        basesCargada,
        proyUlt3AniosCargados,
        instCargadasOk,
        cargandoInstCargadas,
        cargandoProyUlt3Anios,
        cargandoConvocatoria,
        convocatoriaCargada,
        estadoQuery
    } = useSelector( state => state.uiReducer);
    
    const itemsMenu = [ 
        { ruta : ``, etiqueta : 'Proyectos' ,  soloAdmin : false },
        { ruta : `/ayuda` , etiqueta : 'Ayuda' ,  soloAdmin : false },
        // { ruta : '/gestor/cuenta' , etiqueta : 'Mi cuenta' ,  soloAdmin : false },
        { ruta : `/usuarios` , etiqueta : 'Usuarios' ,  soloAdmin : true },
        { ruta : `/evaluacion` , etiqueta : 'Comisiones' ,  soloAdmin : true }
     ]

   

    useEffect(  ()=>{    dispatch(resetUi())
       // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []    );

    useEffect(
      ()=>{   (!cargandoConvocatoria) && (!convocatoriaCargada) && dispatch(startGetConvocatoria())
         // eslint-disable-next-line react-hooks/exhaustive-deps
      }, 
      [dispatch,cargandoConvocatoria,convocatoriaCargada]
    )
    

     
    useEffect(
      ()=>{ (!cargandoConvocatoria) && (convocatoriaCargada) && (!cargandoBases) && (!basesCargada) && dispatch(startMostarBases())  
         // eslint-disable-next-line react-hooks/exhaustive-deps
      },
      [basesCargada,cargandoBases,cargandoConvocatoria,convocatoriaCargada,dispatch]
    )

    useEffect(
      ()=>{ (!cargandoBases) && (basesCargada) && (!cargandoInstCargadas) &&  (!instCargadasOk) && dispatch(startMostarInstitucionesCargadas()) 
         // eslint-disable-next-line react-hooks/exhaustive-deps
      }, 
      [cargandoBases,basesCargada,instCargadasOk,cargandoInstCargadas,dispatch]
    )

    useEffect(
      ()=>{  (!cargandoInstCargadas) && (instCargadasOk)  && (!cargandoProyUlt3Anios) && (!proyUlt3AniosCargados) && dispatch(startMostrarProyUltTresAnios())
         // eslint-disable-next-line react-hooks/exhaustive-deps
       },
      [instCargadasOk , proyUlt3AniosCargados,cargandoProyUlt3Anios,cargandoInstCargadas,dispatch]
    )

    useEffect(
      ()=>{ (!cargandoProyUlt3Anios) && (proyUlt3AniosCargados) &&  dispatch(startMostrarUltimoAviso()); 
         // eslint-disable-next-line react-hooks/exhaustive-deps
      },
      [basesCargada,instCargadasOk,proyUlt3AniosCargados,cargandoProyUlt3Anios,dispatch]
    )
     

  return (
    <div className='container-lg' style={{maxWidth : '85em'}}>
         { 
            (!cargandoConvocatoria) && (!cargandoBases) &&  (!cargandoInstCargadas) && (!cargandoProyUlt3Anios) &&  <ModalBase /> 
          }

        <MenuNavegacion _menuItems={itemsMenu} _menuInicio={true} />
        
        <section className='row mt-3 d-flex justify-content-center'>
          
          <div className='col '>
          {
            basesCargada && instCargadasOk && proyUlt3AniosCargados && convocatoriaCargada && ( estadoQuery === null )
              ?  <InicioRouter /> 
              :  <Spiner />
          }
          </div>
        </section>
       
    </div>
  )
}
