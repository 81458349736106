import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react'


import {  useRouteMatch } from 'react-router-dom';

export const NavItem = ({
  _link = '', 
  _handler = ()=>{}, 
  _etiqueta='',
  _menuInicio = false,
  _icono = undefined
}) => {

  let {url} = useRouteMatch();

  
  return (
    <li className={` list-group-item bg-transparent  text-start border-0`}
        onClick={ (e)=>_handler(e,`${url}${_link}`) }
    >
      <button className={`btn ${_menuInicio ? `btn-outline-light fw-bolder ` : 'btn-oultine-secondary'} ${_menuInicio ? 'border-0' : ''}`}>
        {_icono && <FontAwesomeIcon icon={_icono} size={ _menuInicio ? '1x' : '2x'} color={'gray'} />}
        <span className={`ms-2 text-decoration-none ${_menuInicio ? ' fs-6' : 'text-black fs-6'}  `}>
          {_etiqueta.toUpperCase()}
        </span>
      </button>
        
    </li>
  )
}
