import React, { useEffect } from 'react'

import { useDispatch, useSelector } from 'react-redux'


import { useHistory } from 'react-router-dom'

import {  startMostrarProyecto, startResetPanelProyecto } from '../../actions/p'

import { startGetListaInforme } from '../../actions/informe'
import { startMostrarIdeaProyecto } from '../../actions/ip'

import { useGestionarEstados } from '../../hooks/useGestionarEstados'

import {startGetFormsEvalDelProyecto} from '../../actions/formp'


import { Spiner } from '../../components/Ui/Spiner'

import { MenuPanelProyecto } from '../../components/PanelProyecto/MenuPanelProyecto'
import { TablaHistorialAvance } from '../../components/PanelProyecto/TablaHistorialAvance/TablaHistorialAvance'
import { CabeceraPanel } from '../../components/PanelProyecto/CabeceraPanel'
import { PanelRouter } from '../../router/Inicio/PanelProyecto/PanelRouter'

export const PanelProyecto = () => {

    const dispatch = useDispatch();

    const {pFocus} = useSelector(state => state.pReducer);
    const {convFocus} = useSelector(state => state.convReducer);
    const estadoConvocatoria = convFocus.estados.length 
            ? convFocus.estados[convFocus.estados.length - 1].nom 
            : null;
    const pathArray = useHistory().location.pathname.split('/');

    const {
        formsEvaluacionCargados,
        cargandoFormsEvaluacion,
        listaInfAvanceCargada,
        listaInfFInalCargada,
        cargandoProy,
        proyCargado,
        cargandoIdeaProy ,
        ideaProyCargado 
    } = useSelector( state => state.uiReducer);

    const {
        chequearProyectoConvocatoriaAnterior,
        proyectoEstaAprobado
    } = useGestionarEstados();

    const verificarGetInformesHabilitado = ()=>
        chequearProyectoConvocatoriaAnterior() &&
        proyectoEstaAprobado() &&
        pFocus.codigo ;

    const verificarDataCargada = ()=>{
        if(estadoConvocatoria !== 'REC_IP'){
            return   pFocus && formsEvaluacionCargados && pFocus.historialEstados;
        } else{
            return   pFocus &&  pFocus.historialEstados;
        }
    }
    
    useEffect(()=>{ 
        dispatch(startResetPanelProyecto())  
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[dispatch]);
    
    useEffect(() => { 
        (!proyCargado) && 
        (!cargandoProy) &&  
        dispatch(startMostrarProyecto(pathArray[pathArray.length -1])) 
        // eslint-disable-next-line react-hooks/exhaustive-deps
    } , [dispatch,proyCargado,cargandoProy,pathArray]);

    useEffect( ()=>{ 
        (proyCargado) && 
        (!ideaProyCargado) && 
        (!cargandoIdeaProy) && 
        dispatch( startMostrarIdeaProyecto()) 
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[ pFocus,cargandoIdeaProy,ideaProyCargado,proyCargado,dispatch ])

    useEffect(()=>{ 
        if(chequearProyectoConvocatoriaAnterior()){
            ideaProyCargado && 
            (!formsEvaluacionCargados) && 
            (!cargandoFormsEvaluacion) &&
            dispatch(startGetFormsEvalDelProyecto(pathArray[pathArray.length -1]));
        } else {
            ideaProyCargado && 
            (estadoConvocatoria !== 'REC_IP') && 
            (!formsEvaluacionCargados) && 
            (!cargandoFormsEvaluacion) &&
            dispatch(startGetFormsEvalDelProyecto(pathArray[pathArray.length -1]));
        }
      
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[dispatch,cargandoFormsEvaluacion,formsEvaluacionCargados,ideaProyCargado,pathArray,estadoConvocatoria]);

    useEffect(()=>{
        formsEvaluacionCargados &&
        (!listaInfAvanceCargada) &&
        verificarGetInformesHabilitado() && 
        pFocus.codigo.includes('PEIS') && 
        dispatch( startGetListaInforme(false) ) ;
       // eslint-disable-next-line react-hooks/exhaustive-deps
    },[dispatch,listaInfAvanceCargada,formsEvaluacionCargados]);

    useEffect(()=>{
        formsEvaluacionCargados &&
        (!listaInfFInalCargada) &&
        verificarGetInformesHabilitado() && 
        dispatch( startGetListaInforme(true) ) ;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[dispatch,listaInfFInalCargada,formsEvaluacionCargados]);

    return (
        <div className='container animate__animated animate__fadeIn h-70'>
           {
             (!verificarDataCargada())
             ?<Spiner />
             : 
             <>
                <div className='row'>
                    <div className='col'>
                        <CabeceraPanel />
                    </div>
                </div>
                <div className='row mb-3 h-100'>
                    <div className='col-3 h-100 w-25'>
                        <MenuPanelProyecto />
                    </div>
                    <div className='col-9'>
                        <PanelRouter />
                    </div>
                </div>

                <div className='row'>
                    <div className='col'>
                        <TablaHistorialAvance _tipoUsuario='admin' />
                    </div>
                </div>
                
             </>
            
           }
        </div>
    )

}
    

