import React from 'react'
import { useSelector } from 'react-redux';
import { Switch,Route, useRouteMatch} from 'react-router-dom'
import { FormularioInformeAvance } from '../../../components/FormularioInformeAvance/FormularioInformeAvance';
import { FormularioInformeFinalAET} from '../../../components/FormularioInformeFinalAET/FormularioInformeFinalAET';
import { FormularioInformeFinalPEIS  } from '../../../components/FormularioInformeFinalPEIS/FormularioInformeFinalPEIS';
import { FormEvaluacionCalidad } from '../../../pages/FormularioEvaluacion/FormEvaluacionCalidad';
import { FormEvaluacionIpCA } from '../../../pages/FormularioEvaluacion/FormEvaluacionIpCA';
import { FormEvaluacionIpPE } from '../../../pages/FormularioEvaluacion/FormEvaluacionIpPE';
import { FormProyecto } from '../../../pages/FormularioProyecto/FormProyecto';
import { FormEvaluacionPertinencia } from '../../../pages/FormularioEvaluacion/FormEvaluacionPertinencia';
import { FormIdeaProyecto } from '../../../pages/FormularioIdeaProyecto/FormIdeaProyecto';

export const FormRouter = () => {
    const {pFocus} = useSelector( state => state.pReducer);
  
    const {tipoUsuario} = useSelector(state => state.usrReducer.usrFocus);
    let {path} = useRouteMatch();
  return (
    <Switch>
        <Route path={`${path}/ip`}> <FormIdeaProyecto /> </Route>
        <Route path={`${path}/ip/:idForm`}> <FormIdeaProyecto /> </Route>
        <Route path={`${path}/p/:idForm`}> <FormProyecto /> </Route>
        <Route path={`${path}/eipca/:idForm`}> <FormEvaluacionIpCA _tipoUsuario={tipoUsuario} /></Route>
        <Route path={`${path}/eipca_def/:idForm`}> <FormEvaluacionIpCA _tipoUsuario={tipoUsuario} /></Route>
        <Route path={`${path}/eippe/:idForm`}> <FormEvaluacionIpPE _tipoUsuario={tipoUsuario}/></Route>
        <Route path={`${path}/pcal/:idForm`}><FormEvaluacionCalidad _tipoProyecto={ pFocus ? pFocus.tipoProyecto : 'PEIS'} _tipoUsuario={tipoUsuario} /></Route>
        <Route path={`${path}/pcal_def/:idForm`}> <FormEvaluacionCalidad _tipoProyecto={  pFocus ? pFocus.tipoProyecto : 'PEIS'} _tipoUsuario={tipoUsuario} /></Route>
        <Route path={`${path}/ppert/:idForm`}> <FormEvaluacionPertinencia _tipoUsuario={tipoUsuario}/></Route>
        <Route path={`${path}/ppert_def/:idForm`}><FormEvaluacionPertinencia _tipoUsuario={tipoUsuario} /></Route>
        <Route path={`${path}/inf_avance/:idForm`}><FormularioInformeAvance /></Route>
        {
          pFocus && 
          pFocus.codigo && 
          pFocus.codigo.includes( 'PEIS') && 
          <Route path={`${path}/inf_final/:idForm`}><FormularioInformeFinalPEIS /></Route>
        }
        {
          pFocus && 
          pFocus.codigo && 
          pFocus.codigo.includes( 'AET' )&& 
          <Route path={`${path}/inf_final/:idForm`}><FormularioInformeFinalAET /></Route>
        }
    </Switch>
  )
}
