import React from 'react'
import { Campo } from '../../../components/Formulario/Campo';
import { useFormAdjuntarDocumentos } from './hooks/useFormAdjuntarDocumentos';

export const FormAdjuntarDocumento = () => {

    const {
        campos,
        docElegido,
        handleCampoChange,
        validaciones,
        validarCampo,
        lDocumentacionRequerida,
        handleSubmit,
        proyectoEnEstadoRecepProyecto,
        revisarHabilitacionBotonSubirDocumento,
        revisarHabilitacionBotonSelecArchivo
    } = useFormAdjuntarDocumentos();

    
    console.log(revisarHabilitacionBotonSubirDocumento())

  return (
    <form onSubmit={handleSubmit} encType='multipart/form-data' >
                
    <fieldset>

        <Campo 
            etiqueta='1) Seleccione documento a subir'
            name='documentoElegido'
            type='select'
            opciones={ lDocumentacionRequerida ? lDocumentacionRequerida : [] }
            value={campos['documentoElegido']}
            valido={validaciones['documentoElegido']}
            required={false}
            disabled={ revisarHabilitacionBotonSubirDocumento()  }
            handlecampochange={handleCampoChange}
            handleblur={validarCampo}
        />
        <p> <b>Elegido : </b>  { docElegido ? docElegido.nom : '' } </p>
        
        <label>{'2) Seleccione el archivo en su dispoisitivo'}</label>
        <input  className='form-control mt-3 mb-3 w-50' 
            id='doc_adjunto' 
            name='doc_adjunto' 
            type={'file'} 
            disabled={ revisarHabilitacionBotonSelecArchivo() }
        />
        <label>{'3) Suba el documento al Gestor'}</label>
        <div className='alert alert-warning'>

            <p className='fw-bold'>  Antes de subir el documento, revisar que sea correcto. </p>

        </div>
        {
            proyectoEnEstadoRecepProyecto()
            ? <input className='form-control btn btn-outline-primary mt-3 w-50' 
                type='submit' 
                value='Subir Documento' 
            
            />
            : <></>
        }
    </fieldset>
    
</form>
  )
}
