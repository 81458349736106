import React from 'react';
import {  useSelector } from 'react-redux';
import {  Switch, Route, useRouteMatch} from 'react-router-dom';
import { SeccionFormularios } from '../../../components/PanelProyecto/SeccionFormularios/SeccionFormularios';

/**
 * 
 * @param {*} param0 _tipo = 'estandar' 'admin' 
 * @returns 
 */
export const PanelRouter = () => {

 let {path} = useRouteMatch();
//   const {listaInfAv} = useSelector(state => state.infAvReducer);
  const { parametrosPanel } = useSelector(state => state.uiReducer);
  const { codigo, duracion : bianual } = useSelector(state => state.pReducer.pFocus);
  
  const filtrarFormulariosProyecto = () =>   parametrosPanel.find( item => item._nom === 'proyecto')._linkForm;
  const filtrarFormulariosIdeaProyecto = () =>  parametrosPanel.find( item => item._nom === 'idea_proyecto')._linkForm;
  const obtenerFormulariosInfAvance = ()=>parametrosPanel.find( item => item._nom === 'informe_avance')._linkForm;
  const obtenerFormulariosInfFinal = ()=>parametrosPanel.find( item => item._nom === 'informe_final')._linkForm;

  return (

        <Switch>
             <Route path={`${path}/ip`}>
                  <SeccionFormularios _listaFormularios={filtrarFormulariosIdeaProyecto()} />
              </Route>
              <Route path={`${path}/p`}>
                  <SeccionFormularios _listaFormularios={filtrarFormulariosProyecto()} />
              </Route>
             
              <Route path={`${path}/inf_avance`}>
                  { codigo.includes('PEIS') && bianual &&  <SeccionFormularios _listaFormularios={obtenerFormulariosInfAvance()} />}
              </Route>
              <Route path={`${path}/inf_final`}>
                  {  <SeccionFormularios _listaFormularios={obtenerFormulariosInfFinal()} />}
              </Route>
        </Switch>


  )
}
