import React from 'react'
import {  Switch, Route, useRouteMatch} from 'react-router-dom';
import { Ayuda } from '../../pages/Ayuda/Ayuda';
import { PanelProyecto } from '../../pages/PanelProyecto/PanelProyecto';
import { TablaProyectos } from '../../pages/TablasProyectos/TablaProyectos';
import { PanelComisiones } from '../../pages/PanelComisiones/PanelComisiones';
import { FormRouter } from './Form/FormRouter';

export const InicioRouter = () => {

  let {path} = useRouteMatch();

  return (
    <Switch>
        <Route path={`${path}/ayuda`} > <Ayuda rutaPublica={false}/> </Route>
        <Route path={`${path}/evaluacion`}> <PanelComisiones /></Route>
        <Route path={`${path}/form`}> <FormRouter /> </Route>
        <Route path={`${path}/panel/:idProy`} > <PanelProyecto /> </Route> 
        <Route exact path={`${path}`} > <TablaProyectos /> </Route>
  </Switch>
  )
}
