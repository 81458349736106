import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { salirDelSistema } from '../../actions/usr';
import { NavItem } from './NavItem';
import { mostrarAlerta } from '../../helpers/mostrarAlerta';

export const MenuNavegacion = ({
  _menuItems = [] , 
  _menuInicio = false
}) => {

    const dispatch = useDispatch();
    
    const { tipoUsuario, ape , nom} = useSelector( state => state.usrReducer.usrFocus);
    const {anio, estados} = useSelector( state => state.convReducer.convFocus);

    const history = useHistory();

    const {pathname} = history.location;


    const handleSalir = (e)=>{
      e.preventDefault( );
      dispatch(salirDelSistema(history));
      
    }

    const esRutaDeMenu =()=> 
          pathname.includes('/panel')       ||
          pathname.includes('/evaluacion')  ||
          pathname.includes('/ayuda')       ||
          pathname.includes('/usuarios')    || 
          pathname === '/gestor';



    const handleConfirmarSalirForm = (e,_ruta)=>{
      e.preventDefault();
      console.log(_ruta)
      let redirigir = true;
      
      if(!esRutaDeMenu()){
        mostrarAlerta('¿Desea salir? Se perderán todos los campos que no haya guardado','', true)
          .then(  resp => resp.isConfirmed  ? null :  redirigir = false   );
      } 

      if(redirigir) {
        history.push(_ruta);
      }
      
     
      
    }

    
  return (
    <div className={`row ${_menuInicio ? 'bg-primary  z-index-sticky bg-opacity-90' : 'bg-light'}`}>
       <div className={`col bg-transparent`}>
          <div className={`row  ${_menuInicio ? 'justify-content-center  m-2 ' : ''} `}>
            <div className={`${_menuInicio? 'col-5': 'col' } bg-transparent mt-2`}>

                <ul className={`${_menuInicio ? 'list-group list-group-horizontal' : 'list-group'}`}>
                  {
                    _menuItems.map( 
                      (menuItem,i) => menuItem.soloAdmin 
                        ? tipoUsuario === 'admin'
                          ? <NavItem 
                              key={`item-${menuItem.etiqueta}-${i}`} 
                              _link={menuItem.ruta}
                              _handler={handleConfirmarSalirForm} 
                              _etiqueta={menuItem.etiqueta}
                              _menuInicio={_menuInicio}
                              _icono={menuItem.icono ? menuItem.icono : undefined}

                            /> 
                          : undefined
                        : <NavItem 
                            key={`item-${menuItem.etiqueta}-${i}`} 
                            _link={menuItem.ruta} 
                            _handler={handleConfirmarSalirForm} 
                            _etiqueta={menuItem.etiqueta}
                            _menuInicio={_menuInicio}
                            _icono={menuItem.icono ? menuItem.icono : undefined}

                          />
                    )
                  }
                </ul>
              </div>
            
              {
                _menuInicio && 
                <>
                  <div className='col-3'>
                    
                   <div className='row justify-content-end  align-items-center' style={{height : '100%'}}>
                    {
                      ( tipoUsuario === 'admin') && <div className='col-9 text-light'><small> Convocatoria {anio}:</small> <small> estado {estados[estados.length-1].tipoEstado} </small></div>
                    }
                    
                   </div>
                  </div>
                  <div className='col-2'>
                    <div className='row row-cols-2 justify-content-end align-items-center' style={{height : '100%'}}>
                      {/* <div className='col-4 text-light'>{tipoUsuario === 'estandar' ? 'docente' : tipoUsuario}:</div> */}
                      <div className='col-8 text-light'><small>{ape}, {nom}</small></div>
                      
                    </div>
                  </div>
                  <div className='col-1 '>

                      <button className='btn btn-outline-light fw-bolder fs-4 mt-2 float-end' 
                          onClick={handleSalir}
                      > 
                        <small>Salir</small>
                      </button>
                  </div>    
                
                </>
              }
          </div>
        </div>
       
    </div>
  
  )
}
