import React from 'react'
import {  useSelector } from 'react-redux';
import { ListaIconosDeFormulario } from './ListaIconosDeFormulario';




export const SeccionFormularios = ({
    _listaFormularios = []
}) => {
    const {pFocus} = useSelector( state => state.pReducer)
    const {listaInfAv} = useSelector( state => state.infAvReducer);
    const {listaInfFinal} = useSelector( state => state.infFinalReducer);
    const {lFormsEval} = pFocus;
   
    const noEsRutaDeEvaluacion = (_ruta)=>
        _ruta === '/ip' || 
        _ruta === '/p'  || 
        _ruta === '/inf_avance' || 
        _ruta === '/inf_final';

    const esRutaInformeAvance = (_ruta ) => _ruta === '/inf_avance';
    const esRutaInformeFinal = (_ruta ) => _ruta === '/inf_final';
    const esRutaEvalProg = (_ruta)=> _ruta === '/eippe'

    const verIdFormulario = (_ruta)=>{
        if(_ruta === '/p' ) return pFocus.FormularioProyecto_id;
        if(_ruta === '/ip' ) return pFocus.IdeaProyecto_id;
         
    }
  

    const mapearFormularios = ({ _ruta,_nom},i) =>  {
        let localListaIconosForm = []; 
        if(  noEsRutaDeEvaluacion(_ruta) &&  (!esRutaInformeAvance(_ruta)) &&  (!esRutaInformeFinal(_ruta)) ){
            localListaIconosForm = [{ _id: verIdFormulario(_ruta), _ruta}];
        }
        else if( noEsRutaDeEvaluacion(_ruta) && esRutaInformeAvance(_ruta) ) {
           localListaIconosForm = listaInfAv.map( (iav) => ({_ruta , _id : iav.id}) )
        }
        else if( noEsRutaDeEvaluacion(_ruta) && esRutaInformeFinal(_ruta) ) {
           localListaIconosForm = listaInfFinal.map( (ifinal) => ({ _ruta , _id : ifinal.id}) );
        }
        else if(lFormsEval) {
            
            const lformEvalFiltrado = lFormsEval.filter( form =>  _ruta.slice(1) === form.tipo ) ;
        
            if(lformEvalFiltrado.length > 0 ) {
             localListaIconosForm =   lformEvalFiltrado.map( ({id,evaluador},j) => ({
                    _id : id,
                    _ruta , 
                    _nomUsr : esRutaEvalProg(_ruta) 
                        ? evaluador.nom  
                        : `${evaluador.ape}, ${evaluador.nom}` 
                    
                }) )
            }
        }
   
     
        
        
        return <li className='list-group-item border-0' key={`lista-iconos-${i}`}>
                     <ListaIconosDeFormulario 
                        _listaFormularios={localListaIconosForm} 
                        _tipoForm ={ _nom }
                     />
                  </li>
        
    }
    


  return (
    <div className='row'>
       
        <div className='col'>
            <ul className={`list-group list-group-horizontal ${_listaFormularios.length > 1 ? 'd-flex justify-content-around' : ''} `}>
                {   
                    _listaFormularios &&  _listaFormularios.map( mapearFormularios )
                }
               
            </ul>
        </div>
    
      
    </div>
  )
}
