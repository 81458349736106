
import { useSelector } from 'react-redux';
import { useGestionarEstados } from './useGestionarEstados';

export const useGestionarUsuario = () => {
    const {usrFocus} = useSelector( state => state.usrReducer);
    const {listaP,pFocus} = useSelector( state => state.pReducer);
    const {eipcaFocus} = useSelector(state => state.eipcaReducer);
    const {pcalFocus} = useSelector(state => state.pcalReducer);
    const {eippeFocus} = useSelector(state => state.eippeReducer);
    const {ppertFocus} = useSelector(state => state.ppertReducer);

    const  {
        instanciaEvaluacionCalTerminada,
        instanciaEvaluacionIpTerminada,
        instanciaEvaluacionIpIniciada,
        instanciaEvaluacionPertIniciada,
        chequearEstadoRecepProyecto
    } = useGestionarEstados();
    
    const usuarioEsAdmin = ()=>usrFocus && usrFocus.tipoUsuario === 'admin';
    const usuarioEsGestion = ()=>usrFocus && usrFocus.tipoUsuario === 'gestion';
    const usuarioEsEvaluadorInterno = ()=>usrFocus && (usrFocus.tipoUsuario === 'eval_int' || usrFocus.tipoUsuario === 'eval_int_ext') ;
    const usuarioEsEvaluadorExterno = ()=>usrFocus && usrFocus.tipoUsuario === 'eval_ext';
    const usuarioEsEvaluadorProgramas =()=>usrFocus && usrFocus.tipoUsuario === 'eval_prog';
    const usuarioEsEstandar = ()=> usrFocus && usrFocus.tipoUsuario === 'estandar';
    const usuarioEsRespUUAA = ()=> usrFocus && usrFocus.tipoUsuario === 'sec_unl';
    const usuarioEsConesjoSuperior = ()=> usrFocus && usrFocus.tipoUsuario === 'cs';
    const usuarioHabilitadoCrearProyectos = ()=> 
        usrFocus && 
        usrFocus.puedeCrearProyectos &&
        (!usuarioEsEvaluadorExterno()) &&
        (!usuarioEsConesjoSuperior()) &&
        (!usuarioEsEvaluadorProgramas());

    const usuarioEsDueñoDelProyecto = ()=> 
        listaP && 
        pFocus &&
        listaP.listaProyectosUsuario
             ? listaP.listaProyectosUsuario.find( proy => proy.id === pFocus.id)
             : false;
             
    const evaluadorEsDueñoDeLaEvaluacion = () => {
        let idFormEval = undefined;
        if(eipcaFocus) idFormEval = eipcaFocus.id ;
        if(pcalFocus) idFormEval = pcalFocus.id ;
        if(eippeFocus) idFormEval = eippeFocus.id ;
        if(ppertFocus) idFormEval = ppertFocus.id ;
        if(idFormEval){
            return  pFocus &&
                    (usuarioEsEvaluadorInterno() || usuarioEsEvaluadorExterno() || usuarioEsEvaluadorProgramas()) &&
                    (pFocus.lFormsEval.some( _form => _form.id === idFormEval && _form.evaluador.id === usrFocus.id) );
            }
        return false;
    };
    
    const usuarioEsEvaluadorCalidad = ()=> {
        return usuarioEsEvaluadorExterno() || usuarioEsEvaluadorInterno();
    }
    const verPermisosDeLectura = ()=> usrFocus.permisos.filter( permiso => permiso.lectura)
    
  

    const definirPermisoDeLecturaPorTipoUsuarioYEstadoProy = (_ruta = '')=>{
        let salida = false;
        if(
            usuarioEsAdmin() || 
            usuarioEsConesjoSuperior() ||
            usuarioEsGestion() ||
            usuarioEsRespUUAA()
        ){
            salida = true;
        }
        
        else if( _ruta === '/ppert'){
            salida = usuarioEsEvaluadorInterno() && instanciaEvaluacionPertIniciada();
        }
        else if( _ruta === '/eipca'){
            salida = usuarioEsEvaluadorInterno() && instanciaEvaluacionIpIniciada();
        }
        else if( _ruta === '/pcal'){
            salida = usuarioEsEvaluadorInterno() || usuarioEsEvaluadorExterno() ;
        }
        else if( _ruta === '/eipca_def'){
            salida = usuarioEsEvaluadorInterno() || ( usuarioEsDueñoDelProyecto() && instanciaEvaluacionIpTerminada());
        }
        else if( _ruta === '/pcal_def'){
            salida =  (usuarioEsEvaluadorInterno() || usuarioEsEvaluadorExterno()) || ( usuarioEsDueñoDelProyecto() && instanciaEvaluacionCalTerminada() );
        }
        else if( _ruta === '/ppert_def'){
            salida =  usuarioEsEvaluadorInterno() || ( usuarioEsDueñoDelProyecto() && instanciaEvaluacionIpTerminada());
        }
        else if( _ruta === '/eippe'){
            salida = usuarioEsEvaluadorProgramas() || ( usuarioEsDueñoDelProyecto() && instanciaEvaluacionIpTerminada());
        }
        else if( _ruta === '/ip'){
            salida = true;
        }
        else if( _ruta === '/p' && usuarioEsDueñoDelProyecto()){
            salida = true;
        }
        else if(  _ruta === '/p' && (!usuarioEsDueñoDelProyecto())) {
            salida = chequearEstadoRecepProyecto();
        }
        else if( _ruta === '/inf_avance'){
            salida = instanciaEvaluacionCalTerminada();
        }
        else if( _ruta === '/inf_final'){
            salida = instanciaEvaluacionCalTerminada();
        }
        return salida; 
    }

    return {
        definirPermisoDeLecturaPorTipoUsuarioYEstadoProy,
        verPermisosDeLectura ,
        usuarioEsGestion,
        usuarioEsAdmin,
        usuarioEsDueñoDelProyecto,
        usuarioEsEvaluadorInterno,
        usuarioEsEvaluadorExterno,
        usuarioEsEvaluadorProgramas,
        evaluadorEsDueñoDeLaEvaluacion,
        usuarioEsEstandar,
        usuarioEsRespUUAA,
        usuarioEsEvaluadorCalidad,
        usuarioHabilitadoCrearProyectos,
        usuarioEsConesjoSuperior
    }

}
