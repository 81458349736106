import React , { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { 
   Redirect,
   BrowserRouter as Router,
   Switch 
} from 'react-router-dom';
import { Private } from './Private';
import { Public } from './Public';

import { Ayuda } from '../pages/Ayuda/Ayuda';
import { Inicio } from '../pages/Inicio/Inicio';
import { LoginUsuario } from '../pages/LoginUsuario';
import { RegistroUsuario } from '../pages/RegistroUsuario';


export const AppRouter = () => {


   const {logueado,usrFocus} = useSelector(state => state.usrReducer);

   useEffect(() => { (!logueado) && window.history.pushState({},'','/') }, [logueado])
   
   // const {esperandoBackend} = useSelector(state => state.uiReducer);

   // eslint-disable-next-line react-hooks/exhaustive-deps
   // useEffect(() => { 
   
   //    if( localStorage.getItem('token') !== null) {
         
   //       dispatch( startActualizarToken()  );
   //    }
    
   //  }, [])
      

  return (

     
      <Router>
         <Switch>

            <Public path='/ayuda' > <Ayuda rutaPublica={true} /> </Public>  
            
            <Public path='/login' > <LoginUsuario /> </Public>
         
            <Public path='/register' > <RegistroUsuario /> </Public>
         
            <Private path='/gestor' > 
               <Inicio _tipo={ usrFocus ? usrFocus.tipoUsuario : 'estandar'} /> 
            </Private> 
            
            <Redirect to={'/login'} />
            
         </Switch>
        
      </Router>

    
      
      
      
  )
}
